import { Component, OnInit } from '@angular/core';
import { BackButtonSettings } from '@app/shared/components/sub-header/sub-header.model';

@Component({
  selector: 'app-boletos',
  templateUrl: './boletos.component.html',
  styleUrls: ['./boletos.component.scss']
})
export class BoletosComponent implements OnInit {
  backButtonSettings: BackButtonSettings;

  constructor() {
    this.backButtonSettings = {
      title: 'Voltar'
    };
  }

  ngOnInit(): void {}
}
